import { Link } from 'gatsby';
import * as React from 'react';

const AboutBanner = () => (
  <section
    id="about-banner"
    className="flex items-center justify-center px-6 py-6 mt-12 text-center bg-brand-blue"
  >
    <h2 className="text-4xl font-semibold text-white uppercase font-display">
      Relocating? Stressed?{' '}
      <Link
        to="https://www.frontlineremovals.com.au/contact/"
        className="inline-block px-2 leading-tight rounded bg-brand-orange"
      >
        Talk to us!
      </Link>
    </h2>
  </section>
);

export default AboutBanner;
